import { Card, Box, CardContent, ImageList, ImageListItem, fabClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../utils/axiosInstance';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import { ThreeDots } from 'react-loader-spinner';

const MyBookings = () => {
    const [bookings, setBookings] = useState([])
    const [myBookings, setMyBookings] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let getMyBookings = async () => {
            let response = await axiosInstance.get('/api/my-bookings/',)
            if (response.status === 200) {
                setMyBookings(response.data)
                console.log(myBookings.length)
            }
            setLoading(true)
        }
        getMyBookings()
    }, [])
    const currentDate = new Date().toISOString()


    let getMyBookings = async () => {
        let response = await axiosInstance.get('/api/my-bookings/',)
        if (response.status === 200) {
            setMyBookings(response.data)
            console.log(myBookings.length)
        }
        setLoading(true)
    }



    const deletTask = async (id) => {
        let response = await axiosInstance.delete(`/api/delete-booking/${id}/`)
        console.log(response)
        setMyBookings(myBookings.filter((mybooking) => mybooking.id !== id))
        getMyBookings()
        alert('Deleted Successfully !')

    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        fontFamily: 'Montserrat',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#1f1f1f',
        boxShadow: '0 1px 5px #1f1f1f'
    }));

    return (
        <div className='mybookings-div' > <h2> <center> My Bookings </center></h2>

            {!loading ? <div style={{ position: 'relative', top: '50%' }}>

                <p style={{ position: 'relative', left: '49%', }}>
                    <ThreeDots
                        height="40"
                        width="40"
                        color='#273e47'
                        wrapperClass='true'
                        ariaLabel=''
                    /></p>
                <h4 style={{ position: 'relative', left: '48%' }}>
                    Loading</h4>
            </div> :
                <Box>

                    {myBookings &&
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8, sm: 16, md: 20 }}>

                            {
                                myBookings.length == 0 ? <center><div><h4>No bookings to show</h4></div></center> :
                                    myBookings.map(booking => (

                                        <Grid item xs={4} sm={6} md={4} key={booking.id} >
                                            <div><h3>{booking.date}<span style={{ float: 'right', fontSize: 18, cursor: 'grab', }} onClick={() => deletTask(booking.id)}>🗑️</span></h3></div>
                                            {
                                                (booking.date > currentDate) ?
                                                    <Item >

                                                        <h3>{booking.title}</h3>
                                                        <h4>{booking.room_name}</h4>
                                                        <h5>{booking.start_time} - {booking.end_time}</h5>

                                                    </Item> : <Item>

                                                        <h3 style={{ textDecoration: 'line-through' }}>{booking.title}</h3>
                                                        <h4 style={{ textDecoration: 'line-through' }}>{booking.room_name}</h4>
                                                        <h5 style={{ textDecoration: 'line-through' }}>{booking.start_time} - {booking.end_time}</h5>

                                                    </Item>
                                            }

                                        </Grid>


                                    ))}
                        </Grid>

                    }




                </Box>
            }
        </div>
    )
}

export default MyBookings