import React, { useContext,useEffect } from 'react'
import { TextField, Button, Box, Grid, Typography, InputBase,makeStyles } from '@mui/material/'
import AuthContext from '../context/AuthContext'
import logo from '../assets/login-page.png'
import background from '../assets/background.png'
import { width } from '@mui/system'
import axiosInstance from '../utils/axiosInstance'


const LoginPage = () => {

//  useEffect(()=>{
//   const checkConnection = async () => {
//     const response = await axiosInstance.get('api/check-connection/')

//     if (response.status === 200) {
//       console.log(response.data)

//     }
//   }
//   checkConnection()
//   })


  let { loginUser } = useContext(AuthContext)
  
  

  return (

    <Box className='login-body'>
      <center>

        <Box className='login-page-box' >

          <div className='login-div'>
            <div>
              <form action="" onSubmit={loginUser}>
                <Typography variant='h5'
                  fontFamily={'Montserrat'}
                  color={'#273e47'}
                  fontWeight={'bold'}
                  textAlign={'center'}
                  marginTop={5}
                  marginBottom={5}
                >Login</Typography> 

                <InputBase
                  className= {'login-textfield'}
                  style ={{
                    border:'1.5px solid grey',
                    padding:10,
                    borderRadius:5,
                    fontFamily:'Montserrat',
                  }}
                  inputMode='email'
                  label='Email'
                  name='email'
                  placeholder='Enter Email'
                  size='small'
                  variant='outlined'
                  color={''}
                  fullWidth={true} />
                <div>

                </div>
                <InputBase 
                style ={{
                  border:'1.5px solid grey',
                  padding:10,
                  borderRadius:5,
                  fontFamily:'Montserrat',
                }}
                className="login-textfield" 
                inputMode='text' 
                type={'password'} 
                name='password' 
                label='Password'
                placeholder='Enter Password' 
                size='small' 
                variant='outlined' 
                color='success' 
                fullWidth={true} /><div>

                </div>
                <center>
                  <Button 
                  className='login-button' 
                  type='submit' 
                  name='Submit' 
                  variant='contained' 
                  children='Submit' 
                  fullWidth={true} />
                  </center>
              </form>
            </div>
          </div>
        </Box>

      </center>


    </Box>
  )
}

export default LoginPage