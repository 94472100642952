import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { useEffect, useState, createRef, useContext } from 'react';
import axiosInstance from '../utils/axiosInstance'
import '../App.css';
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Button, CardContent, Card, Grid, Box, Alert, FormGroup } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import dayjs from 'dayjs'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'
import Calendar from 'react-calendar';
import AuthContext from '../context/AuthContext';
import { ThreeDots, Watch } from 'react-loader-spinner'



const MainPage = () => {
    const navigate = useNavigate();
    let authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;
    const calendarRef = createRef(null);
    let { user } = useContext(AuthContext)
    let [bookings, setBookings] = useState([])
    let [bookingID, setBookingID] = useState([])
    let [rooms, setRooms] = useState([])

    const handleClose = () => setOpen(false);
    const handleCreateClose = () => setCreateOpen(false);
    let [loading, setLoading] = useState(false)
    let [bookLoading, setBookLoading] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [createOpen, setCreateOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    const [clickDate, set_clickDate] = useState(new Date())
    const [loc, setLoc] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [start_time, set_StartTime] = useState('');
    const [end_time, set_EndTime] = useState('');
    const [room, setRoom] = useState('');
    const [cc_emails, setCc_emails] = useState('');

    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState('')
    const [alertSeverity, set_alertSeverity] = useState('')
    const [locations, setLocations] = useState([])

    useEffect(() => {
        let getBookings = async () => {
            let response = await axiosInstance.get('/api/all-bookings/')
            if (response.status === 200) {
                setBookings(response.data)
            }
            setLoading(true)
        }
        getBookings()
        getLocations()

    }, [])

    var localizedFormat = require('dayjs/plugin/localizedFormat')
    dayjs.extend(localizedFormat)

    let getBookings = async () => {
        let response = await axiosInstance.get('/api/all-bookings/')
        if (response.status === 200) {
            setBookings(response.data)
        }
        setLoading(true)
    }

    let getBookingsWithID = async (id) => {
        let response = await axiosInstance.get(`/api/meeting/${id}/`)
        if (response.status === 200) {
            console.log(response.data)
            setBookingID(response.data)
        }
        setLoading(true)
    }
    let getRoomWithLocations = async (id) => {
        let response = await axiosInstance.get(`/api/location/${id}/`)
        if (response.status === 200) {
            console.log(response.data)
            setRooms(response.data)
        }
        setLoading(true)

    }

    const getLocations = async () => {
        let response = await axiosInstance.get(`api/locations/`)
        if (response.status === 200) {
            setLocations(response.data)
            console.log(response.data)
        }
        setLoading(true)
    }

    function tConvert(date, time) {
        var converted = dayjs(`${date}` + "T" + `${time}`).format('hh:mm a')
        return converted
    }

    function DisplayAlert(message, severity) {

        return <Alert variant='standard' sx={{ margin: 1 }} onClose={() => {
            setShowAlert(false)
        }} severity={severity}>{message}</Alert>

    }


    const addBooking = async (e) => {
        e.preventDefault()

        let formField = new FormData()
        formField.append('room', room.id)
        formField.append('title', title)
        formField.append('date', date)
        formField.append('start_time', start_time)
        formField.append('end_time', end_time)
        formField.append('cc_emails', cc_emails)

        await axios({
            url: `https://jafferbrothers.developmint.xyz/backend/api/booking/`,
            method: 'POST',
            data: formField,
            headers: {
                Authorization: `Bearer ${authToken?.access}`

            },

        },

            setBookLoading(true),

        ).then(response => {
            if (response.data === 'Ok') {
                setAlertContent(`Meeting Room Booked Successfully. Confirmation mail has been sent to ${user.email}.`)

                setCreateOpen(false)
                set_alertSeverity('success')
                setShowAlert(true)
                getBookings()
                setDate([])
                setTitle('')
                setRoom('')
                set_StartTime('')
                set_EndTime('')
                setCc_emails('')
                setLoc('')
            }
            else if (response.data === 'Busy') {
                handleCreateClose(true)
                setAlertContent('Sorry, Meeting Room is Busy. Please choose another Time or Day.')
                set_alertSeverity('warning')
                setShowAlert(true)
                set_StartTime('')
                set_EndTime('')

            }
            else if (response.data === 'Error') {
                handleCreateClose(true)
                setAlertContent('An Unexpected Error occured, Please try again.')
                set_alertSeverity('error')
                setShowAlert(true)
                

            }            
            navigate('/calendar')
            setBookLoading(false)

            console.log(loading)


        }).catch(err => console.log(err))

    }





    let array = []
    bookings.forEach(function (booking) {
        let meets = {};

        meets.id = booking.id
        meets.title = String("» ").concat("  " + booking.title)
        meets.start = String(booking.date).concat("T", (booking.start_time))
        meets.end = String(booking.date).concat("T", (booking.end_time))
        array.push(meets)
    })

    let jsonArray = JSON.parse(JSON.stringify(array))



    const handleEventClick = (eventClickInfo) => {
        getBookingsWithID(eventClickInfo.event.id)
        handleOpen()
    }
    const Item = styled(Paper)(({ theme }) => ({

        position: 'absolute',
        top: '50%',
        left: '50%',
        border: '0px solid transparent',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#1f1f1f',
        boxShadow: '0 2px 10px #1f1f1f',
        paddingLeft: 50,
        paddingRight: 5,
        paddingTop: 10,
        paddingBottom: 10,
        minWidth: '20%'
    }));

    const onChangeHandler = (text) => {
        setCc_emails(text)
    }


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    return (
        <>
            <Box className='calendar-box'>
                {showAlert && DisplayAlert(alertContent, alertSeverity)}

                {!loading ?
                    <div style={{ position: 'relative',top:'50%'}}>

                        <p style={{ position: 'relative', left: '46%', }}>
                            <ThreeDots
                                height="40"
                                width="40"
                                color='#273e47'
                                wrapperClass='true'
                                ariaLabel=''
                            /></p>
                        <p style={{ position: 'relative', left: '45.2%' }}>
                            Loading</p>
                    </div> :
                    <Grid className='calendar' container spacing={2} >
                        <Grid className='calendar-side' item md={3} xs={12}>
                            <Calendar calendarType='US' view='month' onChange={set_clickDate} value={clickDate}
                                
                                onClickDay={(e) => {
                                    console.log(e)
                                    const selectedDate = formatDate(e);
                                    setDate(selectedDate)
                                    setCreateOpen(true)
                                    console.log(`selected date : ${selectedDate}`)

                                }
                                }
                                

                            />

                        </Grid>


                        <Grid item md={9} xs={12}>
                            <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                initialView="dayGridMonth" headerToolbar={{
                                    right: 'dayGridMonth,listWeek,timeGridDay,listYear,listDay',
                                    left: 'today,prev,next',
                                    center: 'title'
                                }}
                                buttonText={{
                                    listWeek: 'Week',
                                    month: 'Month',
                                    timeGridDay: 'Day',
                                    listYear: 'Year',
                                    listDay: 'List',
                                    today: 'Today'
                                }}
                                selectOverlap={false}
                                height={670}
                                contentHeight={500}
                                expandRows={false}
                                stickyFooterScrollbar={true}
                                handleWindowResize={true}
                                dayHeaders={true}
                                buttonIcons={true}
                                events={jsonArray}
                                eventMinWidth={'80%'}
                                ref={calendarRef}
                                weekends={false}
                                dateClick={
                                    (dateClickInfo) => {
                                        calendarRef.current.getApi().changeView('timeGridDay', dateClickInfo.dateStr)
                                        console.log(dateClickInfo.dateStr)

                                    }
                                }
                                eventClick={handleEventClick}
                                allDaySlot={false}


                            />

                            <Modal
                                keepMounted
                                open={open}
                                onClose={handleClose}
                            >

                                <Item variant='elevation' xs={'auto'} sm={'auto'} md={'auto'}>
                                    {loading ?
                                        <div>
                                            <p style={{ textAlign: 'right', paddingRight: 20, cursor: 'pointer' }} onClick={handleClose}>✖</p>
                                            <div style={{ paddingRight: 100 }}>

                                                <p style={{ fontSize: 25, fontWeight: 500, paddingBottom: -20 }}>📌 {bookingID.title}</p>
                                                <p style={{ fontSize: 16, fontWeight: 500, paddingLeft: 45 }}>{dayjs(`${bookingID.date}`).format('LL')}</p></div>
                                            <h4>📍 : {bookingID.room_name}
                                                <br />
                                                <small style={{ fontWeight: 600, paddingLeft: 20 }}>- {bookingID.location}</small>
                                            </h4>

                                            <p><b>⌛ :</b> {tConvert(bookingID.date, bookingID.start_time)} - {tConvert(bookingID.date, bookingID.end_time)}</p>
                                            <h4><b>✍ :</b> {bookingID.first_name} {bookingID.last_name}</h4>
                                        </div> : <h4>Loading..</h4>

                                    }
                                </Item>
                            </Modal>
                            <Modal keepMounted open={createOpen} onClose={() => {
                                setCreateOpen(false)
                                setDate('')
                            }}  >
                                <Card className='create-card' style={{
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    scrollBehavior: 'smooth'
                                }}>
                                    <p style={{ textAlign: 'right', paddingLeft: '90%', cursor: 'pointer', marginBottom: 5 }} onClick={handleCreateClose}>✖</p>

                                    {bookLoading ? <center><div style={{ textAlign: 'center', position: 'relative', justifyContent: 'center' }}>
                                        <p style={{ paddingLeft: '40%' }}> <ThreeDots
                                            height="40"
                                            width="40"
                                            color='#273e47'

                                        /></p>
                                        <p>
                                            Booking in Process...</p>
                                    </div></center> :
                                        <div>
                                            <p style={{ fontWeight: 'bold', fontSize: 20, color: '#2e3e47', textAlign: 'center', marginBottom: 10, marginTop: 10 }}> [ Book your Meeting ] </p><br />
                                            <FormControl required={true} fullWidth={true} onSubmit={addBooking} >
                                                <form action="">

                                                    <div style={{ justifyContent: 'space-between', display: 'flex', }}><label> Location *</label><label> Room *</label></div>

                                                    <div style={{ display: 'flex', justifyContent: 'space-around', }}>
                                                        <Select
                                                            style={{ marginRight: 10 }}
                                                            required={true}
                                                            name='Location'
                                                            fullWidth={true}
                                                            value={loc} >
                                                            {locations.map((loc) => {
                                                                return <MenuItem key={loc.id}
                                                                    onClick={() => {
                                                                        setLoc(loc)
                                                                        console.log(loc)
                                                                        console.log(loc.id)
                                                                        getRoomWithLocations(loc.id)
                                                                    }}

                                                                    value={loc}>
                                                                    {loc.location}

                                                                </MenuItem>
                                                            })}

                                                        </Select>

                                                        <FormControl fullWidth={true}>

                                                            <Select
                                                                required={true}
                                                                style={{ marginLeft: 10 }}
                                                                disabled={
                                                                    loc.length == 0 ? true : false
                                                                }
                                                                name='room'
                                                                fullWidth={true}

                                                                value={room}
                                                                onChange={(e) => {
                                                                    setRoom(e.target.value);
                                                                    console.log(e)

                                                                }

                                                                }>
                                                                {rooms.map((room) => {
                                                                    return <MenuItem key={room.id}
                                                                        value={room}>
                                                                        {room.room_name}

                                                                    </MenuItem>
                                                                })}

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <br />
                                                    <div><div><label>Title *</label></div>
                                                        <TextField required={true} fullWidth key='title' inputMode={'text'} type={'text'} placeholder='Enter Title' name='title' value={title} onChange={(e) => {
                                                            setTitle(e.target.value)
                                                        }} /></div>
                                                    <br />

                                                    <div><div><label>Date *</label></div>
                                                        <TextField type="date" fullWidth inputMode='date' name='date' value={date} required={true} onChange={(e) => {
                                                            setDate(e.target.value)
                                                        }} /></div>
                                                    <br /><div>
                                                        <div style={{ justifyContent: 'space-around', display: 'flex', }}><label>Start Time *</label><label>End Time *</label></div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-around', }}>
                                                            <TextField style={{ marginRight: 10 }} type="time" fullWidth placeholder='Enter Start Time' name='start_time' value={start_time} required={true} onChange={(e) => {
                                                                set_StartTime(e.target.value)
                                                            }} />
                                                            <TextField type="time" fullWidth placeholder='Enter End Time' name='end_time' value={end_time} required={true} onChange={(e) => {
                                                                set_EndTime(e.target.value)
                                                            }} /></div>


                                                    </div>
                                                    <br />
                                                    <div><div><label>CC Emails (Optional) : </label></div>
                                                        <TextField
                                                            value={cc_emails}
                                                            onChange={(e) => {
                                                                onChangeHandler(e.target.value)
                                                            }}
                                                            required={false}
                                                            fullWidth
                                                            inputMode={'text'}
                                                            type={'text'}
                                                            placeholder='Enter Emails separated with commas '
                                                            name='cc-emails'
                                                            autoComplete='none'

                                                        />

                                                    </div>
                                                    <br />
                                                    <center>
                                                        <Button type="submit" fullWidth style={{ backgroundColor: '#273e47', marginBottom: 20 }} variant='contained' onClick={addBooking}> Book </Button></center>
                                                </form>
                                            </FormControl>

                                        </div>

                                    }
                                </Card>
                            </Modal>
                        </Grid>
                    </Grid>
                }
            </Box>

        </>
    )


}

export default MainPage