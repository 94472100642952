import './App.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AuthProvider } from "./context/AuthContext";
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './utils/PrivateRoute';
import MyBookings from './pages/MyBookings';
import MainPage from './components/Calendar';
import Appbar from './components/Appbar'

function App() {
  return (
    <div className="App">
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      <Router>

        <AuthProvider>
          <Appbar />
          <Routes>


            <Route path='/login' element={<LoginPage />} />

            <Route path='/homepage' element={<PrivateRoute>
              <HomePage />
            </PrivateRoute>} />

            <Route path='/my-bookings' element={<PrivateRoute>
              <MyBookings />
            </PrivateRoute>} />

            <Route path='/calendar' element={
              <PrivateRoute>
                <MainPage />
              </PrivateRoute>
            } />

          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
