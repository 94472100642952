import { React, useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthContext'
import axiosInstance from '../utils/axiosInstance'
import { Link, useNavigate } from 'react-router-dom';
import AlertComponent from '../components/AlertComponent'
import { Alert } from '@mui/material';
import { Box } from '@mui/system';
const HomePage = () => {
  let [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [text, setText] = useState('')
  const [cc_mails, setCC_mails] = useState('')
  const [concatt, setConcatt] = useState('')
  const [showAlert,setShowAlert] = useState(false)
  const [lastestBookings, setLatestBookings] = useState([])
  const [bookings, setBookings] = useState([])
  let {user} = useContext(AuthContext)

  useEffect(() => {

    const getUsers = async () => {
      const response = await axiosInstance.get('api/list-users/')

      if (response.status === 200) {
        console.log(response.data)

        setUsers(response.data)
      }
    }
    getUsers()

    const getLatestBookings = async () => {
      const response = await axiosInstance.get('api/latest-bookings/')

      if (response.status === 200) {
        console.log(response.data)

        setLatestBookings(response.data)
      }
    }
    getLatestBookings()


    const getAllBookings = async () => {
      const response = await axiosInstance.get('api/all-bookings/')

      if (response.status === 200) {

        console.log(response.data)
        setBookings(response.data)
      }
    }

    getAllBookings()

  }, [])

  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = users.filter(user => {
        const regex = new RegExp(`${text}`, "gi");
        return user.email.match(regex)
      })
    }
    console.log(matches)
    setCC_mails(matches)
    setText(text)
  }

const onSuggestHandle=(text)=>{
  
  setText(text + ',');
  setCC_mails([]);
  
  setConcatt(concatt.concat(text + ",").replace('@gmail.com', ''))
  setText('')
}

  return (
    <div className='container' >
      <Box>
{showAlert && <Alert severity= 'success' sx={{width:'100%'}} >This is a success alert — check it out!</Alert>}
</Box>
      <input className='suggestion-input' type="text" value={text}
      onClick = {(e)=>{
        if(e.key === 'Enter'){
          alert('text:' , text)
        }
      }}
      onChange={(e) => {
        onChangeHandler(e.target.value)
      }} />
      {cc_mails && cc_mails.map((cc, i) => (
        <div className='suggestion-box' onClick={()=>{
          onSuggestHandle(cc.email)
          
        }
        
        } key={i}><small>{cc.email}</small></div>
   ))}
   <p>{concatt}</p>

<br /><br /><br />
{showAlert && <Alert  sx={{width:'100%'}} severity="error" >This is an error alert — check it out!</Alert>}
<button
onClick={()=>{
 setShowAlert(true)
}}
>Alert</button>


<div style={{textAlign:'left'}}>

<ol>

{lastestBookings.map((LB)=>(
  <li> <p>{LB.title}</p>
        <small>{LB.date}</small>
  </li>
  
))}

</ol>

</div>

<div>
  <h5>Meetings in which you are CC'ed</h5>
<ol>
{
  bookings.map((bk)=>(
   <small>{
    String(bk.cc_emails).includes(user.email) ?<li> {bk.title } </li> : null
    }</small>
  ))}
</ol>
</div>


    </div>




  )
}


export default HomePage