import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AuthContext from '../context/AuthContext'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { fontFamily } from '@mui/system';



const Appbar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    let { user, logout } = React.useContext(AuthContext)
    const navigate = useNavigate();
    const [isHovering, setIsHovering] = React.useState(false);

    const handleMouseOver = () => {

        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    function userEmail() {
        if (user) {
            const email = user.email
            const trimmedEmail = email.substring(0, email.lastIndexOf('@'))
            const str = trimmedEmail.slice(1)
            const upperCasedName = trimmedEmail.charAt(0).toUpperCase() + str
            return <Button
                onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                className=''
                style={{
                    backgroundColor: '#273e47',
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 500,
                    color: 'whitesmoke',
                    textDecoration: 'none',
                    paddingRight: 10
                }}><span className="material-symbols-outlined" style={{
                    paddingRight: 5
                }} >
                    account_circle
                </span>
                <span>{
                    isHovering && (
                        <span style={{ color: 'whitesmoke', fontSize: 18, fontFamily: 'Montserrat', fontWeight: 600, letterSpacing: 0 }}>
                            {upperCasedName}
                        </span>
                    )}</span>
            </Button>

        }
        else {
            return ''
        }

    }
    return (
        <AppBar position="absolute" style={{ backgroundColor: '#273e47', height: 45 }} sx={{ pr: 0, mr: 0 }}>
            <Container maxWidth="xxl" sx={{ paddingRight: 0, marginRight: 0 }} >
                <Toolbar disableGutters style={{ maxHeight: 45, minHeight: 45, fontFamily: 'Montserrat' }} sx={{ paddingRight: 0, marginRight: 0 }}>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            letterSpacing: '.1rem',
                            color: 'whitesmoke',
                            textDecoration: 'none',
                        }}
                    >
                        MS
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Button
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        > <Typography variant='h5' style={{}}>≡</Typography>
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                            <MenuItem onClick={handleCloseNavMenu}>

                                <Link className='nav-link' to="/calendar" sx={{ textDecoration: 'none', color: '#273e47', }} >
                                    <Button sx={{ color: '#273e47', display: 'block', fontFamily: 'Montserrat', textDecoration: 'none', }}> Calendar</Button></Link>

                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Link className='nav-link' to="/my-bookings" sx={{ textDecoration: 'none', color: '#273e47', }}>
                                    <Button sx={{ color: '#273e47', display: 'block', fontFamily: 'Montserrat', textDecoration: 'none', }}> My Bookings</Button></Link>
                            </MenuItem>

                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: 'block', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            letterSpacing: '.1rem',
                            color: 'whitesmoke',
                            textDecoration: 'none',
                        }}
                    >
                        MS
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                        <Link className='nav-link' to="/calendar"><Button sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'Montserrat' }}

                        >Calendar
                        </Button></Link>
                        <Link className='nav-link' to="/my-bookings"><Button sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'Montserrat' }}
                        > My Bookings
                        </Button></Link>

                    </Box>

                    <Box sx={{ flexGrow: 0 }}>

                        {userEmail()}
                        {user ? <Link className='nav-link' to="/login"><Button className='' style={{ color: 'whitesmoke', textDecoration: 'none', backgroundColor: '', height: 45, marginTop: 2, textTransform: 'none', borderRadius: 0, fontSize: '1rem', fontFamily: 'Montserrat' }}
                            onClick={logout}
                        >
                            <p className="material-symbols-outlined">
                                logout
                            </p>
                        </Button></Link> : <Button className='' style={{ color: 'white', textDecoration: 'none', backgroundColor: '', height: 45, marginTop: 2, textTransform: 'none', borderRadius: 0, fontSize: '1rem', fontFamily: 'Montserrat' }}>
                            <p>Login</p>
                        </Button>}
                    </Box>

                </Toolbar>
            </Container>

        </AppBar>
    )
}

export default Appbar