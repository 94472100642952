import { createContext, useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios'

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null);
    const navigate = useNavigate();
    let [loading, setLoading] = useState(true);
    
    let loginUser = async (e) => {
        e.preventDefault()
        setLoading(false)
        let response = await fetch('https://jafferbrothers.developmint.xyz/backend/api/token/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'email': e.target.email.value, 'password': e.target.password.value }),
        
            
        })
        let data = await response.json()
        console.log('data:', data)
        console.log('reponse:', response.body)

        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
            navigate('/calendar')
            setLoading(true)
            window.location.reload()
        }
        
        else {
            console.log(response)
            alert(data.detail)
        }

    }

    let logout = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate('/login')
        console.log('logged out')
    }

    let contextData = {
        user: user,
        loginUser: loginUser,
        logout: logout,
        authTokens: authTokens,
    }


    useEffect(() => {

        if(authTokens){
            setUser(jwtDecode(authTokens.access))
        }
        setLoading(false)

    },[authTokens,loading])


    return (
        <AuthContext.Provider value={contextData}>
            {loading ? <center><h5>Loading...</h5></center> : children}
        </AuthContext.Provider>
    )
}